import React from "react";

import classes from "./Header.css";

interface HeaderProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export default function Header(props: HeaderProps) {
  return (
    <div className={[classes.header, props.className].join(" ")} style={props.style}>
      {props.children}
      {/* <Avatar className={classes.profilePhoto}>IU</Avatar> */}
    </div>
  );
}
