import React from "react";
import { Button, Paper } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import copy from "copy-to-clipboard";
import { format, differenceInMinutes, addMonths } from "date-fns";
import { FileCopy, Launch } from "@material-ui/icons";
import classes from "./Card.css";

export interface CardProps {
  id: string;
  status: string;
  name: string;
  ip: string;
  created: Date;
  onDelete: (event: React.MouseEvent<SVGSVGElement, MouseEvent>, props: CardProps) => void;
}

export default function Card(props: CardProps) {
  const hostname = `${props.name}.${process.env.BASE_HOSTNAME}`;
  const user = "ec2-user";

  const rabbitPort = 15672;
  const consulPort = 8500;
  const portainerPort = 9000;
  const mongoPort = 27017;
  const mongoCollection = "integrate_local";

  return (
    <Paper elevation={3} className={[classes.card].join(" ")}>
      <CardHeader status={props.status}>
        <HeaderLine title="Name" value={props.name} copyText={props.name} />
        <HeaderLine title="Status" value={props.status} />
        <HeaderLine
          title="Cost"
          value={`\$${(
            (Math.abs(differenceInMinutes(props.created, new Date())) + 1) *
            (5 / 24 / 60)
          ).toFixed(2)}`}
        />
        <HeaderLine title="IP" value={props.ip} />
      </CardHeader>

      <CardBody>
        <div className={classes.applicationList}>
          <Link title="Application" url={`https://${hostname}`} />
          <Link title="Portainer" url={`http://${props.ip}:${portainerPort}`} />
          <Link title="Consul" url={`http://${props.ip}:${consulPort}`} />
          <Link
            title="Auth0ClientId"
            url={`http://${props.ip}:${consulPort}/ui/dc1/kv/lde/Auth0ClientId/edit`}
          />
          <Link title="Rabbit" url={`http://${props.ip}:${rabbitPort}`} />
        </div>

        <div className={classes.codeList}>
          <CodeLine title="Host" value={hostname} />
          <CodeLine
            title="SSH"
            value={`ssh ${user}@${hostname}`}
            href={`ssh://${user}@${hostname}`}
          />
          <CodeLine
            title="Mongo"
            value={`mongodb://${hostname}:${mongoPort}/${mongoCollection}`}
            href={`mongodb://${hostname}:${mongoPort}/${mongoCollection}`}
          />
          <CodeLine
            title="Git"
            value={`git remote add ${props.name} ssh://${user}@${hostname}/root/repos/server.git`}
          />
        </div>
      </CardBody>

      <CardFooter>
        <div className={classes.info}>
          <div>
            Created: {format(props.created, 'yyyy-MM-dd')}
          </div>
          <div>
            Removal: {format(addMonths(props.created, 1), 'yyyy-MM-dd')}
          </div>
        </div>
        <Button color="secondary">
          <DeleteIcon onClick={(_) => props.onDelete(_, props)} />
        </Button>
      </CardFooter>
    </Paper>
  );
}

//#region Card Components

function CardHeader(props) {
  const statusClass = props.status === "running" ? classes.running : classes.stopped;

  return (
    <Paper elevation={4} className={[classes.header, statusClass].join(" ")}>
      {props.children}
    </Paper>
  );
}

function CardBody(props: { children: React.ReactNode }) {
  return <div className={classes.body}>{props.children}</div>;
}

function CardFooter(props) {
  return (
    <>
      <hr />
      <div className={classes.footer}>{props.children}</div>
    </>
  );
}

//#endregion

//#region Line Components

function CodeLine(props) {
  const hrefIcon = props.href ? (
    <Launch
      fontSize={"small"}
      onClick={() => window.open(props.href, "_blank")}
      className={classes.icon}
    />
  ) : (
    <div />
  );

  const copy2Clipboard = () => copy(props.value);

  return (
    <>
      <div>{props.title}: </div>
      <input className={classes.codeInput} type="text" value={props.value} readOnly />
      <FileCopy fontSize={"small"} onClick={copy2Clipboard} className={classes.icon} />
      {hrefIcon}
    </>
  );
}

function Link(props) {
  return (
    <a className={classes.link} href={props.url} target="_blank">
      {props.title}
    </a>
  );
}

function HeaderLine(props) {
  const cursor = props.copyText != null ? "pointer" : "auto";

  return (
    <>
      <div>{props.title}: </div>
      <div
        style={{ fontWeight: "bold", cursor, overflow: "hidden" }}
        onClick={() => props.copyText && copy(props.copyText)}
      >
        {props.value}
      </div>
    </>
  );
}

//#endregion
