export default class AwsService {
  private static baseUrl = process.env.SERVER_URL;

  static async GetRDEs() {
    const res = await fetch(`${AwsService.baseUrl}/api/`);

    const jsonRes = await res.json();

    return jsonRes.Reservations;
  }

  static GetConnectedStatus(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => {
        controller.abort();
        resolve(false);
      }, 1000);

      const res = await fetch(`${AwsService.baseUrl}/api/`, {
        method: "OPTIONS",
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      resolve(res.ok);
    });
  }

  static async CreateRDE(name: string) {
    const res = await fetch(`${AwsService.baseUrl}/api/`, {
      method: "POST",
      body: JSON.stringify({
        tags: [
          {
            name: "Name",
            value: name,
          },
        ],
      }),
    });

    return res.ok;
  }

  static async DeleteRDE(id: string) {
    const res = await fetch(`${AwsService.baseUrl}/api/${id}`, { method: "DELETE" });

    return res.ok;
  }
}
