import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Card, { CardProps } from "../Card/Card";
import Body from "../Body/Body";
import AddIcon from "@material-ui/icons/Add";
import Sidebar from "../Sidebar/Sidebar";
import AwsService from "../../services/AwsService";
import Swal from "sweetalert2";
import Logo from "../../images/Integrate-Logo-Business-Starts.svg";
import "normalize.css";
import classes from "./App.css";
import SearchInput from "../SearchInput/SearchInput";
import { addMonths, format } from "date-fns";

const refreshTime = 30000;
const connectRefreshTime = 5000;

let refreshData;
let refreshConnectionStatus;

export default function App() {
  const serverStatus = useServerState();
  const rdes = useRdes();
  const [searchTerm, setSearchTerm] = useState<string>("");

  return (
    <>
      <Header className={classes.header} style={{ backgroundColor: serverStatus ? "" : "#C54E57" }}>
        <Logo />
        <div></div>
        <SearchInput onChange={setSearchTerm} />
        <div style={{ alignSelf: "center" }}>
          {serverStatus ? "Connected to VPN" : "Disconnected from VPN"}{" "}
        </div>
        <AddIcon onClick={e => handleAdd(e, rdes)} style={{ alignSelf: "center", cursor: "pointer" }} />
      </Header>

      <Sidebar className={classes.sidebar} />

      <Body className={classes.body}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {rdes.filter(bySearchTerm(searchTerm)).map(toCard)}
        </div>
      </Body>
    </>
  );
}

//#region Effects

function useRdes() {
  // Pull existing rdes from localstorage
  const [rdes, setRdes] = useState<Array<Aws.RDE>>(
    JSON.parse(localStorage.getItem("rdes") ?? "[]")
  );

  // Effect to fetch data and refresh
  useEffect(() => {
    // Inital data fetch
    refreshData = async () => {
      const rdes = (await AwsService.GetRDEs()).sort(sortRdes);

      localStorage.setItem("rdes", JSON.stringify(rdes));
      setRdes(rdes);
    };

    refreshData();

    // Refreshing data every {refreshTime}
    const interval = setInterval(() => refreshData(), refreshTime);

    // Clear interval when unmounting
    return () => clearInterval(interval);
  }, [1]);

  return rdes;
}

function useServerState() {
  const [serverStatus, setServerStatus] = useState<boolean>(false);

  // Effect to fetch data and refresh
  useEffect(() => {
    // Inital data fetch
    refreshConnectionStatus = async () => {
      const connected = await AwsService.GetConnectedStatus();

      setServerStatus(connected);
    };

    refreshConnectionStatus();

    // Refreshing data every {refreshTime}
    const interval = setInterval(() => refreshConnectionStatus(), connectRefreshTime);

    // Clear interval when unmounting
    return () => clearInterval(interval);
  }, [1]);

  return serverStatus;
}

//#endregion

//#region Data Functions

function toCard(rde: Aws.RDE) {
  const instance = rde.Instances.length > 0 ? rde.Instances[0] : null;

  return (
    <Card
      key={rde.ReservationId}
      id={instance?.InstanceId ?? ""}
      ip={instance?.PublicIpAddress ?? instance?.PrivateIpAddress ??  ""}
      name={rdeToName(rde) ?? ""}
      status={instance.State.Name ?? ""}
      created={new Date(instance.LaunchTime)}
      onDelete={handleDelete}
    />
  );
}

function sortRdes(a, b) {
  const aDate = new Date(a.Instances[0].LaunchTime);
  const bDate = new Date(b.Instances[0].LaunchTime);

  return aDate < bDate ? 1 : bDate < aDate ? -1 : 0;
}

function bySearchTerm(searchTerm: string) {
  return (rde: Aws.RDE) => rdeToName(rde).toLowerCase().includes(searchTerm.toLowerCase());
}

//#endregion

//#region Event Handlers

async function handleAdd(event: React.MouseEvent<SVGSVGElement, MouseEvent>, rdes: Aws.RDE[]) {
  const { isConfirmed, value: rdeName } = await Swal.fire({
    input: "text",
    icon: "question",
    titleText: "What should the RDE be named?",
    html: `<div style="color:red">This RDE will be removed on ${format(addMonths(new Date(), 1), 'yyyy-MM-dd')}</div>`,
    confirmButtonText: "Create this RDE",
    inputValidator: (_) => {
      return !_.startsWith("rde-")
        ? "RDE names start with rde-"

        : /[A-Z]/.test(_)
        ? "RDE names must be all lowercase"

        : rdes.map(rdeToName).some(n => n.includes(_))
        ? "RDE names must not be substrings of other RDEs"

        : null;
    },
    inputValue: "rde-",
  });

  if (!isConfirmed) return;

  const isCreated = await AwsService.CreateRDE(rdeName.toLowerCase());

  if (isCreated) {
    await Swal.fire({
      icon: "success",
      titleText: "RDE Created",
      confirmButtonText: "Thank you",
    });

    if (refreshData) refreshData();
  } else {
    await Swal.fire({
      icon: "error",
      titleText: "Could not create RDE!",
      confirmButtonText: "Understood",
    });
  }
}

async function handleDelete(event: React.MouseEvent<SVGSVGElement, MouseEvent>, props: CardProps) {
  const value = await Swal.fire({
    input: "text",
    icon: "warning",
    titleText: "Are you sure?",
    html: `This cannot be undone. <br/> This will permanently delete <b>${props.name}</b> <br/> Please type <b>${props.name}</b> to confirm.`,
    confirmButtonText: "I understand the consequences, delete this RDE",
    confirmButtonColor: "#dd6b55",
    inputValidator: (_) => (_ == props.name ? null : "Please enter RDE name"),
  });

  if (!value.isConfirmed) return;

  const isDeleted = await AwsService.DeleteRDE(props.id);

  if (isDeleted) {
    await Swal.fire({
      icon: "success",
      titleText: "RDE Deleted",
      confirmButtonText: "Thank you",
    });

    if (refreshData) refreshData();
  } else {
    await Swal.fire({
      icon: "error",
      titleText: "Could not delete RDE!",
      confirmButtonText: "Understood",
    });
  }
}

function rdeToName(rde: Aws.RDE){
  const instance = rde.Instances.length > 0 ? rde.Instances[0] : null;

  let name = "";
  if (instance && instance.Tags && instance.Tags.length > 0) {
    name = instance.Tags[0].Value;
  }

  return name;
}

//#endregion
