import React from "react";

import classes from "./Body.css";

interface BodyProps {
  className?: string;
  children?: React.ReactNode;
}

export default function Body(props: BodyProps) {
  return <div className={[classes.body, props.className].join(" ")}>{props.children}</div>;
}
