import React from "react";
import Logo from "../../images/Integrate-Logo-Business-Starts.svg";
import { saveAs } from "file-saver";

import classes from "./Sidebar.css";

const sshConfig = new Blob(
  [
    `Host *.integrate.team
  User ec2-user
  IdentityFile ~/.ssh/aws_rde_key

Host *.integrate.com
  User ec2-user
  IdentityFile ~/.ssh/aws_rde_key`,
  ],
  { type: "text/plain" }
);

interface SidebarProps {
  className?: string;
}

export default function Sidebar(props: SidebarProps) {
  return (
    <nav className={[classes.sidebar, props.className].join(" ")}>
      <ul className={classes.sidebarList}>
        <li>RDEs Dashboard</li>
        <li>
          <a href="http://34.222.68.245:4440/project/Tools/job/show/ce03015c-51e9-46ee-b99b-686e1d8a18a7" target="_blank">
            Fix an RDE
          </a>
        </li>
        <li>
          <a onClick={() => saveAs(sshConfig, "config.ssh")}>SSH Config</a>
        </li>
      </ul>
    </nav>
  );
}
