import React from "react";
import { render } from "react-dom";
import App from "./components/App/App";

async function main() {

  if(window.location.protocol == "https:") {
    window.location.href =
      window.location.href.replace('https:', 'http:');
  }

  render(<App />, document.getElementById("root"));
}

main();
